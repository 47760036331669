.inputField {
  text-align: left !important;

  p {
    text-align: left !important;
    margin-bottom: 3px !important;
    margin-top: 3px !important;
  }

  .MuiSelect-select {
    text-align: left !important;
  }

  .MuiFormControl-root {
    min-width: 100% !important;
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .MuiFormControlLabel-root {
    margin-left: 0px !important;
  }
}

.label .MuiTypography-root {
  font-size: 14px !important;
}