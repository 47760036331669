.innerContianer {
  width: 98%;
  height: 98%;
  margin: 1% auto;

  @media only screen and (max-width: 640px) {
    // width: 98vw;
    // height: 98vh;
    // overflow: scroll;
    // width: 140px;
  }
}

@media screen and (min-width: 1500px) {
  #gauge-chart {
    width: 75%;
    margin: auto;
    margin-top: 10px !important;
  }

  #remove-top-margin {
    margin-top: 8% !important;
  }
}

@media screen and (min-width: 2000px) {
  #gauge-chart {
    width: 50%;
    margin: auto;
  }

  #remove-top-margin {
    margin-top: 20px !important;
  }
}

@media screen and (min-width: 3000px) {
  #gauge-chart {
    width: 30%;
    margin: auto;
  }

  #remove-top-margin {
    margin-top: 10px !important;
  }
}

@media screen and (min-width: 4000px) {
  #gauge-chart {
    width: 20%;
    margin: auto;
  }

  #remove-top-margin {
    margin-top: 10px !important;
  }
}

.innerContianerMobile {
  height: 98%;
  width: 100%;

  //margin: 1% auto;
  @media only screen and (max-width: 640px) {
    // width: 98vw;
    // height: 98vh;
    // overflow: scroll;
    // width: 140px;
  }
}

.welcomeText {
  font-weight: 500;
  text-align: left;
}

.subText {
  color: grey;
  font-size: 14px;
}

.headerText {
  text-align: left;
  font-weight: 500;
  font-size: 18px;
}

.primaryRow {
  width: 100%;
  display: flex;
  min-height: 36px;
  margin: 5px auto;
  align-items: center;
  justify-content: space-between;

  .searchBar {
    width: 20%;
    height: 24px;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid grey;
  }
}

.fulfillmentMobileLable {
  color: rgba(0, 0, 0, 0.6);
}

.fulfillmentMobileNameLabel {
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
}

.tabs-container {
  >div:first-child {
    // modifies the background color
    background-color: #f6f6f6 !important;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  >div:last-child {
    // changes the distance between tabs and content
    margin-top: 10px;
  }

  .tab {
    >div>div {
      // modifies the font size of the tab labels
      font-size: 10px;

      svg {
        // modifies the color of SVG icons
        fill: #626262 !important;
      }
    }
  }
}

.mobileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;

}

.mobileFulfilmentDataTable {
  border-radius: 16px;
  border: 2px solid #dddddd;
  box-shadow: 0px 0px 8px 0px #0000000f;
  width: 95%;
  display: flex;
  flex-direction: column;
  padding-top: 24px;

  .mobileRow {
    padding-left: 8px;
    padding-right: 8px;
    color: black;
    width: 100%;
    border-bottom: 1px solid #DDDDDD;
    display: flex;
    flex-direction: row;

    .mobileCellLeft {
      padding-top: 11px;
      border-right: 1px solid #DDDDDD;
      min-width: 95px;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      color: #676767;
      font-size: 12px;
      align-items: start;
      text-wrap: nowrap;

      .cellMeta {
        text-align: start;
        height: 32px;
      }
    }

    .mobileCellRight {
      padding-top: 11px;
      padding-left: 8px;
      width: 95%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      color: black;
      font-size: 12px;
      align-items: start;
      overflow: hidden;

      .orderIdLabel {
        display: flex;
        flex-direction: row;
        gap: 8px;
        flex-wrap: nowrap;
        overflow-x: scroll;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 32px;
      }

      .cellInfoMain {
        display: flex;
        flex-direction: row;
        gap: 8px;
        flex-wrap: nowrap;
        overflow-x: scroll;
        height: 100%;
        align-items: center;
      }

      .cellInfo {
        width: 100%;
        text-align: start;
        text-wrap: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        margin-bottom: 2px;
        height: 32px;
      }
    }
  }
}

.fulfilledDataTable {
  border: 2px solid #dddddd;
  box-shadow: 0px 0px 8px 0px #0000000f;

  .rdt_TableHeadRow {
    background: white;
    color: black;
    font-size: 14px !important;
    text-align: start;

    .rdt_TableCol {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }

  .rdt_TableRow {
    border-top: 1px solid #DDDDDD !important;
    border-bottom: 0px !important;
  }

  .rdt_TableRow:nth-child(odd) {
    background-color: #f9f9f9;
    color: black;
  }

  .rdt_TableCell {
    font-size: 13px !important;
    text-align: start;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.fulfilledPaginationWeb {
  display: flex;
  justify-content: center;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border: 2px solid #dddddd;
  box-shadow: 0px 0px 8px 0px #0000000f;
  background: white !important;
  color: black !important;

  .MuiPaginationItem-page.Mui-selected {
    background: white;
    color: black !important;
  }

  .MuiPaginationItem-page.Mui-selected:hover {
    background: white !important;
    color: #d93a2f !important;
  }

  .MuiTablePagination-root,
  .MuiSvgIcon-root {
    color: black !important;
  }
}

.expandedParent {
  .expandedSection {
    display: flex;
    flex-direction: row;
    gap: 24px;
    padding: 4px, 0px, 4px, 0px;
    align-items: center;
    justify-content: start;
    border-top: 1px solid #DDDDDD;

    .item {
      text-align: start;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
  .expandedSectionMobile {
    margin-top: 20px;
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-left: 5px;
    align-items: flex-start;
    justify-content: flex-start;
    box-shadow: #dddddd;
    border-radius: 10px;
    background-color: #fff;
    padding-bottom: 20px;
    padding-top: 15px;
    overflow-y: auto;

    .item {
      text-align: start;
      display: flex;
      flex-direction: row;
      gap: 2px;
    }
  }
}

.red-outline-btn {
  background-color: #fff !important;
  color: #e7131a !important;
  padding: 8px 12px !important;
  min-height: 24px;
  max-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-family: "Roboto" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  text-transform: none !important;
  border: 1.5px solid #e7131a !important;
  gap: 8px !important;
  border-radius: 4px;
  text-wrap: nowrap;
}

.filterDrawer {
  width: 420px;
  padding: 24px;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
  padding-bottom: 24px;
  overflow-y: scroll;

  @media (max-width: 768px) {
    width: 100vw;
  }

  .heading {
    font-size: 26px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
  }

  .filterSection {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .filterSectionTitle {
      font-size: 13px;
      font-weight: 500;
      color: #000;
    }

    .filters {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .item {
      padding: 2px;
      padding-right: 10px;
      width: 100%;
      height: 48px;
      border-radius: 8px;
      background-color: #eeeeee;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .filterName {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      width: min-content;
      text-wrap: nowrap;
    }

    .filterOutline {
      border: 1px solid red;
    }
  }
}