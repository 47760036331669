$small: 300px;
$medium: 900px;

.App {
  text-align: center;
  font-family: "Roboto";
}

.drawerDiv {
  display: flex;

  .menu {
    display: none;
  }

  .drawer {
    width: 20%;
    min-width: 240px;
    max-width: 240px;
    background: #333;
    position: fixed;
    padding-top: 20px;
  }

  .drawer:hover .menu {
    display: block;
  }

  .renderComponent {
    width: 100%;
    background: #F9F9F9;
    min-width: calc(100% - 240px);
    margin-bottom: 20px;

    @media only screen and (max-width: 640px) {
      width: 100vw;
      max-width: 100vw;
    }
  }

  .renderExternalComponent {
    width: 100%;
    background: #f3f3f4;
    min-width: 100%;
    max-width: 100%;

    @media only screen and (max-width: 640px) {
      width: 100vw;
      max-width: 100vw;
    }
  }
}

.UserError {
  margin-top: 50vh;

  @media only screen and (max-width: 640px) {
    margin-top: 0vh;
  }

  .title {
    font-size: 50px;
    color: #d93a2f;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subtitle {
    font-size: 25px;
  }
}

.rtoc {
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 640px) {
    flex-direction: column;
  }
}

// Dashboard

//Accordians

.accordianHeading {
  padding-right: 3px !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  margin-right: 5px !important;
}

.accordianAvatar {
  background-color: #e7131a !important;
  width: 25px !important;
  height: 25px !important;
  margin-top: 3px !important;
  font-size: 0.95rem !important;
}

.listHeading {
  font-size: 13px !important;
  font-weight: 500 !important;
}

// mobile detaik items
.itemHeading {
  font-family: "Roboto" !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  /* 133.333% */
  letter-spacing: 0.06px !important;
}

.itemElement {
  font-family: "Roboto" !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  margin-top: 2px !important;
  line-height: 20px !important;
}

//custom cards
.dCardCustom {
  cursor: pointer;
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px !important;

  @media screen and (min-width: $small) and (max-width: $medium) {}

  .dCardDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .dCardContentCustom {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    padding: 16px 32px 16px 32px;
  }

  .dCardHeader {
    align-items: center;
    display: flex;
    justify-content: center;
    //color: #D93A2F;

    .header {
      text-align: center;

      p {
        color: white;
        font-size: 26px;
        font-weight: 700;

        // @media screen and (min-width: $small) and (max-width: $medium) {
        //   //css code
        //   font-size: 16px;
        // }
      }
    }

    .icon {
      color: white;
      @media screen and (min-width: $small) and (max-width: $medium) {
        //css code
        font-size: 30px;
      }
      width: 40px;
    }
  }

  .number {
    color: white;
    font-weight: 700;
    font-size: 26px;
  }
}

// all cards
.dCard {
  border-radius: 16px !important;
  padding: 16px, 32px, 16px, 32px;
  border: 2px solid #DDD !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.06) !important;

  @media screen and (min-width: $small) and (max-width: $medium) {}

  .dCardContent {
    padding: 16px 32px 16px 32px;
  }

  .dCardHeader {
    align-items: center;
    display: flex;
    justify-content: center;
    //color: #D93A2F;

    .header {
      text-align: center;

      p {
        font-size: 20px;
        font-weight: 700;

        // @media screen and (min-width: $small) and (max-width: $medium) {
        //   //css code
        //   font-size: 16px;
        // }
      }
    }

    .icon {
      @media screen and (min-width: $small) and (max-width: $medium) {
        //css code
        font-size: 30px;
      }

      width: 40px;
    }
  }

  .dCardLeftHeader {
    display: flex;
    color: lightgray;
    margin-left: 8px;
  }

  .number {
    color: #e7131a;
    //margin-top: 20px;

    @media screen and (min-width: $small) and (max-width: $medium) {
      //css code
      font-size: 24px;
    }
  }

  .customNumber {
    font-size: 18px;
    font-weight: 500;

    // @media screen and (min-width: $small) and (max-width: $medium) {
    //   //css code
    //   font-size: 24px;
    // }
  }

  .btn {
    margin-top: 18px;
    font-size: 18px;
    min-width: 100px;
    cursor: pointer;

    @media screen and (min-width: $small) and (max-width: $medium) {
      //css code
      font-size: 16px;
    }
  }
}

.dCardMobileView {
  @media screen and (min-width: $small) and (max-width: $medium) {
    //css code
    height: 145px;
    min-width: 100px;
    max-width: 142px;
  }
}

.mobileCardScroll {
  @media screen and (min-width: $small) and (max-width: $medium) {
    //css code
    flex-wrap: inherit !important;
    overflow: auto !important;
  }
}

// Order Fulfillment
.fTabs {
  @media screen and (min-width: $small) and (max-width: $medium) {
    //css code
    font-size: 12px !important;
  }

  button.MuiTab-root {
    opacity: 1;
    min-height: auto;
    color: #888888;

    @media screen and (min-width: $small) and (max-width: $medium) {
      //css code
      min-height: auto;
    }
  }

  button.Mui-selected {
    color: #333333 !important;
    font-weight: 700;
    border-bottom: 4px solid red;
  }

  button.MuiTab-root>.MuiTab-wrapper {
    display: contents !important;
  }

  .fTabIcons {
    margin-right: 14px;
  }
}

.btn {
  color: white !important;
  background: #d93a2f !important;
}


.exportBtn {
  color: #d93a2f !important;
  background: white !important;
  border: 1px solid #d93a2f !important;
  margin-bottom: 4px !important;
}

.fQuickFilters {
  background: white;
  border: 1px solid #d93a2f;

  .headingContainer {
    display: flex;
    color: #d93a2f;
    padding-bottom: 5px;
    padding-top: 5px;
    background: #eee;
  }

  .heading {
    .fTabIcons {
      vertical-align: middle;
    }

    font-size: 14px;
    text-align: center;
    color: #d93a2f;
  }

  .btnStack {
    justify-content: center;
    padding: 6px;
  }

  .topheading {
    .fTabIcons {
      vertical-align: middle;
    }

    font-size: 19px;
  }
}

.scandit-container {
  //height: calc(100% - 95px - 56px) !important
  height: 81vh !important;
}

.btnStack {
  .MuiButton-label {
    font-size: 12px;
  }
}

.dataTable {
  .rdt_TableHeadRow {
    background: #D93A2F;
    color: white;
    font-size: 14px !important;
  }

  .rdt_TableCell {
    font-size: 14px !important;
  }
}

.dataTableOrder {
  .rdt_TableHeadRow {
    background: white;
    color: black;
    font-size: 14px !important;
  }

  .rdt_TableCell {
    font-size: 14px !important;
  }
}

.dataTable {
  .rdt_TableHeadRow {
    background: white;
    color: black;
    font-size: 18px !important;
    // border-radius: 8px;
  }

  .rdt_TableCell {
    font-size: 13px !important;
    color: #676767 !important;
  }

  .rdt_TableRow {
    background-color: #EEEEEE;
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.12);
    // border-radius: 8px;
  }
}

.dataTableRounded {
  .rdt_TableHeadRow {
    background: #EEEEEE;
    color: black;
    font-size: 18px !important;
    // border-radius: 8px;
  }

  .rdt_TableCell {
    font-size: 14px !important;
  }

  .rdt_TableRow {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.12);
    // border-radius: 8px;
  }
}

.dataTableDashboardExpanded {
  padding: 10px 10px 10px 10px; 

  .rdt_TableHeadRow {
    background: #EEEEEE;
    color: black;
    font-size: 14px !important;
    font-weight: 600;
  }

  .rdt_TableCell {
    font-size: 14px !important;
    color: #676767 !important;
  }

  .rdt_TableRow {
    background: white !important;
  }
}

.stepContaoner {
  .Mui-completed {
    color: #d93a2f !important;
  }

  .MuiStepIcon-text {
    color: #d93a2f !important;
  }

  .Mui-active {
    color: #d93a2f !important;
  }
}

.orderDetailMobileContainer {
  .MuiFormLabel-root {
    font-size: 1.2rem !important;
  }
}

.mobileNavigationBarss {
  .MuiBottomNavigation-root {
    background: #333;
    box-shadow: 2px 0px 6px 0px rgba(74, 71, 71, 0.75);
    -webkit-box-shadow: 2px 0px 6px 0px rgba(74, 71, 71, 0.75);
    -moz-box-shadow: 2px 0px 6px 0px rgba(74, 71, 71, 0.75);
    padding-bottom: inherit;
    padding-left: 7px;
  }
}

.paginationWeb {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid white;
  border-top: 1px solid white;

  .MuiPaginationItem-page.Mui-selected {
    background: white;
    color: #d93a2f !important;
  }

  .MuiPaginationItem-page.Mui-selected:hover {
    background: white !important;
    color: #d93a2f !important;
  }

  .MuiTablePagination-root,
  .MuiSvgIcon-root {
    color: white !important;
  }
}

.paginationReceiveOrdersWeb {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid white;
  border-top: 1px solid white;

  .MuiPaginationItem-page.Mui-selected {
    background: white;
    color: #d93a2f !important;
  }

  .MuiPaginationItem-page.Mui-selected:hover {
    background: white !important;
    color: #d93a2f !important;
  }

  .MuiTablePagination-root,
  .MuiSvgIcon-root {
    color: white !important;
  }
}

.fixedPagination {
  background: rgb(217, 58, 47);
  position: fixed !important;
  z-index: 999;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.scrolledTopFix {
  background: #DDD;
  position: fixed !important;
  z-index: 999;
  top: 0;
  width: 100%;

  justify-content: center;
}

.scrolledTopFixFulFillOrders {
  background: #f3f3f4;
  position: fixed !important;
  z-index: 999;
  top: 0;
  width: 100%;

  justify-content: center;
}

.searchWeb {
  width: 321px;
  margin-bottom: 3px;
  margin-top: 10px;
  padding: 0px !important;
  background-color: #ffffff;
}

.appBarPrimary {
  background: #d93a2f !important;
  color: white;
}

.scanColor {
  color: #d93a2f !important;
  margin-left: 4px !important;
}

.scanColorWhite {
  color: #ffffff !important;
  margin-left: 4px !important;
}

.speedDialPickOrder {
  bottom: 0 !important;

  .MuiButtonBase-root {
    background: #d93a2f !important;
    color: white;
  }
}

.customerDetailsBoxPickOrder {
  position: fixed;
  width: 100%;
  bottom: 53px;
}

.paperToBottomPadding {
  padding-top: 6px;
  padding-bottom: 6px;
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}

.textCenter {
  text-align: center;
}

.backdrop {
  width: 100%;

  background: #d93a2f;
}

.topHeading {
  width: 100%;
}

.white-bg-color {
  background-color: white !important;
}

.gridHeading>p {
  color: '#333333';
  font-size: 20px !important;
  font-weight: 700;
}

.gridHeading {
  margin: 10px 2px 16px 2px;
}

.title {
  padding: 5px 15px;
  font-size: 25px;
  color: #ffffff;
  font-weight: 500px;
  display: flex;
  align-items: center;
}

.white {
  background: white !important;
}

.mainContainer {
  background: #ffffff !important;

  // border: 1px solid #eee;
  @media screen and (min-width: $medium) {
    //css code
    border: 1px solid #c3c3c3;
  }
}

.mainContainerPackOrderGenerateLabel {
  background: #ffffff !important;
  // border: 1px solid #eee;
  border-left: 1px solid #c3c3c3;
  border-right: 1px solid #c3c3c3;
  border-bottom: 1px solid #c3c3c3;
}

.mainContainerPackOrderHelp {
  border: 1px solid #c3c3c3;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}

.rightAlign {
  display: flex;
  justify-content: flex-end;
}

.btnMblCancel {
  width: 33% !important;
  padding: 3px !important;
  font-size: 10px !important;
  margin-right: 4px !important;
}

.btnMblResource {
  width: 31% !important;
  padding: 3px !important;
  font-size: 10px !important;
}

.containerTypeBarCodeMbl {
  position: fixed;
  width: 100%;
  z-index: 99999;
  top: 82%;
}

.customChip {
  border-radius: 23px !important;
  padding: 11px !important;
  font-size: 18px !important;
  height: 52px !important;
}

.customAccordian {
  .MuiAccordionSummary-root {
    background: #d93a2f !important;
    color: white !important;
    min-height: 47px !important;

    .MuiAccordionSummary-content p {
      font-size: 16px !important;
    }

    .MuiAccordionSummary-expandIconWrapper svg {
      color: white !important;
    }
  }

  .tblAccordian {
    p {
      font-size: 12px;
    }

    .tblAccordianTd {
      display: flex !important;
      font-weight: 500 !important;

      svg {
        margin-right: 7px !important;
      }
    }

    .txtLeftBold {
      text-align: left;

      p {
        color: #3f3f3f;
        font-size: 15px;
      }
    }
  }
}

.textAlignLeft {
  text-align: left;
}

.txtQP {
  .MuiOutlinedInput-input {
    height: 6px !important;
  }
}

.primaryColor {
  color: #d93a2f !important;
}

.packTopTileIcon {
  background: #d93a2f;
  border-radius: 50px;
  color: white;
  height: 26px !important;
  width: 26px !important;
  padding: 4px;
}

.packTopTilekey {
  margin-left: 6px !important;
  margin-top: 5px !important;
  font-size: 1rem !important;
}

.packTopTileValue {
  margin-top: 5px !important;
  margin-left: 7% !important;
  font-weight: 800 !important;
  font-size: 1rem !important;
}

.drpConatinerBoxType {
  text-align: center !important;
}

.inputsNormalGrid {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.verticalCenter {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.packOrderUnPackagedHeader {
  padding: 5px !important;
  background: #d93a2f !important;
  //margin-top: 5px !important;
  color: white !important;
  border-radius: 0 !important;
  box-shadow: none !important;

  h6 {
    color: white !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
  }
}

.packOrderPackageHeader {
  padding: 5px !important;
  background: #d93a2f !important;
  //margin-top: 5px !important;
  color: white !important;
  border-radius: 0 !important;

  h6 {
    color: white !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
  }
}

.packOrderPackageHeaderPackChild {
  padding: 5px !important;
  background: #DDD !important;
  margin-top: 5px !important;
  color: black !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;

  h6 {
    color: black !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
  }
}

.boxCharacterHeader {
  display: flex;
  justify-content: space-between;
  background: #f5f5f5 !important;
  color: #888 !important;
  padding: 10px;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.packOrderPackageHeaderChild {
  padding: 5px !important;
  background: #f2554a !important;
  color: white !important;
  border-radius: 0 !important;

  .icon {
    font-size: 3rem;
    border-radius: 50px;
    margin-right: 20px;
  }

  h6 {
    color: white !important;
    font-weight: 600 !important;
    font-size: 1rem !important;
  }
}

.orderBoxDiv {
  width: 98%;
  margin-left: 10px;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.25),
    0px 1px 1px 1px rgba(0, 0, 0, 0.16), 0px 1px 3px 1px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  margin-bottom: 15px;
}

.orderBoxDiv-Mobile {
  width: 100%;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.25),
    0px 1px 1px 1px rgba(0, 0, 0, 0.16), 0px 1px 3px 1px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  margin-bottom: 15px;
}

.orderCharaterBoxDiv {
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.25),
    0px 1px 1px 0px rgba(0, 0, 0, 0.16), 0px 1px 3px 0px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  margin-bottom: 5px;
}

.packOrderPackageItem {
  padding: 5px !important;

  border-radius: 0 !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;

  .headerRow {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.packOrderItemImage {
  //border: 1px solid #eee;
  padding: 10px;
  height: 100px !important;
}

.popupHeader {
  background-color: green;
  width: 75%;
  display: flex;
  justify-content: center;
  padding: 10px;
  gap: 10px;
  border-radius: 10px;
}

.inputField {
  text-align: left !important;

  p {
    text-align: left !important;
    margin-bottom: 3px !important;
    margin-top: 3px !important;
  }

  .MuiSelect-select {
    text-align: left !important;
  }

  .MuiFormControl-root {
    min-width: 100% !important;
    margin-top: 3px !important;
    margin-bottom: 3px !important;
  }

  .MuiFormControlLabel-root {
    margin-left: 0px !important;
  }
}

.chipTracking {
  svg {
    color: white !important;
  }
}

.customRadio {
  .MuiFormControlLabel-root {
    background: #eee;
    width: 100%;
    margin-top: 6px;
    margin-bottom: 6px;
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .PrivateSwitchBase-input {
    color: #f2554a !important;
  }

  .MuiFormControlLabel-root:hover {
    background: white;
    border: 1px solid #eee !important;
  }
}

.externalcustomRadio {
  .MuiFormControlLabel-root {
    background: #eee;
    width: 100%;
    margin-top: 6px;
  }

  .PrivateSwitchBase-input {
    color: #f2554a !important;
  }

  .MuiFormControlLabel-root:hover {
    background: white;
    border: 1px solid #eee !important;
  }
}

.externalcustomRadio2 {
  .MuiFormGroup-root {
    display: block;
  }

  .MuiFormControlLabel-root {
    background: #eee;
    width: 33%;
    display: inline-block;
    padding: 0;
    margin-right: 0;
  }

  .PrivateSwitchBase-input {
    color: #f2554a !important;
  }

  .MuiFormControlLabel-root:hover {
    background: white;
    border: 1px solid #eee !important;
  }
}

.MuiAccordionSummary-root {
  min-height: 35px !important;
}

.MobileDrawerButtonsExternalLink {
  color: #ffffff !important;
  border: 1px solid #227575 !important;
  width: 48% !important;
  background-color: #227575 !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  white-space: nowrap !important;
}

.MobileDrawerButtonsExternalLinkDisabled {
  color: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.26) !important;
  width: 48% !important;
  background-color: rgba(0, 0, 0, 0.26) !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  white-space: nowrap !important;
}

.MobileDrawerButtons {
  color: #ffffff !important;
  border: 1px solid #d93a2f !important;
  width: 48% !important;
  background-color: #d93a2f !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  white-space: nowrap !important;
}

.MobileDrawerButton {
  color: #ffffff !important;
  border: 1px solid #d93a2f !important;
  width: 98% !important;
  background-color: #d93a2f !important;
  margin-left: 2px !important;
  margin-right: 2px !important;
  white-space: nowrap !important;
}

.SearchHover:hover {
  background-color: #eee;
}

.ActionLoader {
  background-size: 20vh 20vh;
  background-color: rgba($color: #0a0d0f, $alpha: 0.5);
  //background-color: #0A0D0F ;
  bottom: 0;
  height: 100%;
  left: 0;
  //opacity: .2000;
  // -moz-opacity: .55;
  position: fixed;
  width: 100%;
  z-index: 999999;
}

.fQuickFiltersFulfillOrders {
  background: white;
  //margin-top: 3px;
  border: 1px solid #d93a2f;
  border-radius: 2px !important;

  .MuiAccordionSummary-content {
    margin: 0px !important;
  }

  .headingContainer {
    display: flex;
    color: #d93a2f;
    padding-bottom: 0px;
    padding-top: 0px;
    background: #eee;
  }

  .heading {
    .fTabIcons {
      vertical-align: middle;
    }

    font-size: 14px;
    text-align: center;
    color: #d93a2f;
  }

  .btnStack {
    justify-content: center;
    padding: 6px;
  }

  .topheading {
    .fTabIcons {
      vertical-align: middle;
    }

    font-size: 19px;
  }
}

.backGroundWhite {
  background: white;
}

.disabledColor {
  color: rgba(0, 0, 0, 0.26) !important;
}

.disabledButton {
  background-color: rgba(0, 0, 0, 0.26) !important;
}

.enabledColor {
  color: #d93a2f !important;
}

.overlay {
  cursor: no-drop;
}

.disableClass {
  opacity: 0.7;
}

.flexClass {
  display: flex;
  justify-content: space-between;
}

.AuditFiltersContainer {
  padding: 5px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 640px) {
    flex-direction: column;
    align-items: center;
  }

  .chipFilters {
    display: flex;
    width: 100%;

    @media only screen and (max-width: 640px) {
      flex-direction: column;
      align-items: center;
    }

    .logType {
      width: 820px;
    }

    .appType {
      width: 160px;
    }
  }

  .pagination {
    height: 80px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    .buttons {
      display: flex;
      margin-left: 10px;
      align-items: center;

      .text {
        width: auto;
        margin: 0 5px;
        min-width: 100px;
      }
    }

    .dropdown {
      width: 60px;
      padding: 2px;
      margin-left: 5px;
      background: #eaeaea;
    }
  }
}

.techAuditDetailTableRowHeight {
  height: 50px;
}

.techAuditDetailfirstTd {
  width: "30%";
}

.techAuditDetailsecondTd {
  width: "70%";
  text-align: left;
}

.resourceCount {
  color: #24499a;
  font-size: 12px;
  padding: 1px 4px 1px 4px;
  border-radius: 4px;
  position: absolute;
  margin-top: -2px;
  margin-left: 6px;
  background: #96cbf6;
  width: 17px;
  height: 17px;
  text-align: center;
}

.picklistTableCollaped {
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  padding-left: 3px;
}

.packingListTable {
  border-spacing: 0;
}

@media only screen and (max-width: 1360px) and (min-width: 400px) {
  .selectStore {
    margin-left: 30px;
  }
}

.image-zoom {
  position: relative;
  cursor: zoom-in;
}

.image-zoom::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  display: none;
}

.image-zoom:hover::before {
  display: block;
}

.image {
  margin-right: 10px;
  padding: 2px;
  width: 130px;
  height: 130px;
  object-fit: cover;

  @media only screen and (max-width: 640px) {
    width: 80px;
    height: 80px;
  }

  background-size: cover;
  border: 1px solid lightgrey;
}

.remove-buttons input[type="number"]::-webkit-inner-spin-button,
.remove-buttons input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.selectStore .MuiAutocomplete-inputRoot {
  flex-wrap: nowrap !important;
}

.scroll-class {
  overflow-x: auto;
}

.scroll-class::-webkit-scrollbar {
  width: 0;
}

.scroll-class::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: 0;
}

.vl {
  border-left: 2px solid lightgrey;
  height: 50px;
  margin-top: 18px;
}

.tiles-icons {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.tiles-header-font {
  color: #7d8c81;
  margin-bottom: 0;
  margin-top: 20px;
  margin-left: 5px;
  font-size: 12px;
  font-weight: bold;
  word-break: break-word;
}

.tiles-header-font-span {
  color: #7d8c81;
  margin-bottom: 0;
  margin-left: 5px;
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
}

.tiles-value-font {
  margin-top: 5px;
  margin-left: 10px;
  word-break: break-word;
  font-weight: bold;
  font-size: 24px;
}

.icons-oh {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgb(131, 58, 180);
  background-image: linear-gradient(136deg,
      rgb(242, 113, 33) 0%,
      rgb(233, 64, 87) 50%,
      rgb(138, 35, 135) 100%);
}

.gradient-color {
  color: linear-gradient(136deg,
      rgb(242, 113, 33) 0%,
      rgb(233, 64, 87) 50%,
      rgb(138, 35, 135) 100%);
}

.desc-font-oh {
  font: 14px Roboto, Helvetika, Arial, sans-serif;
}

.header-font-oh {
  color: #7d8c81;
  margin-bottom: 0;
  margin-top: 15px;
  margin-left: 5px;
  font: 12px Roboto, Helvetika, Arial, sans-serif;
  font-weight: 400;
  word-break: break-word;

}

.value-font-oh {
  margin-top: 5px;
  margin-left: 5px;
  word-break: break-word;
  margin-bottom: 15px;
  font: 14px Roboto, Helvetika, Arial, sans-serif;
  font-weight: bold;
  font-weight: 500;
}

.grey-value-font {
  color: "white";
  font-size: "12px";
}

.sticky {
  position: fixed;
}

.navigation-icon {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: white;
  transition: color 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  color: rgb(128, 128, 128);
}

.navigation-icon:hover {
  background-color: #1976d2;
  border: none;
  color: white;
}

.circle-buttons {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: white;
  transition: color 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  color: rgb(97, 97, 97);
}

.circle-buttons:hover {
  background-color: rgb(33, 150, 243);
  border: none;
  color: white;
}

.navigation-border {
  height: 100%;
  position: absolute;
  top: 0;
}

.component-section {
  background-color: #F9F9F9;
}

.back-button {
  background-color: #bbb !important;
  color: #333 !important;
  padding: 2px 8px !important;
  height: 48px !important;
  font-family: "Roboto" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  border-radius: 8px !important;
  text-transform: none !important;
}

.small-heading {
  color: #555 !important;
  font-family: "Roboto" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 20px !important;
}

.medium-heading {
  color: #676767 !important;
  font-family: "Roboto" !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0.08px !important;
}

.large-heading {
  color: #333 !important;
  font-family: "Roboto" !important;
  font-size: 26px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 32px !important;
}

.card-heading {
  font-family: "Roboto" !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 26px !important;
  margin-top: 15px !important;
  margin-bottom: 20px !important;
  gap: 16px !important;
}

.card-divider {
  border: 1px !important;
}

.iconBtn {
  background-color: #bbb !important;
  border-radius: 4px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
  font-weight: 700 !important;
  width: auto !important;
  cursor: pointer !important;
}

.iconBtn.selected {
  background-color: #E7131A !important;
  color: white !important;
}

.mobile-filters-btn {
  background-color: #bbb !important;
  border-radius: 4px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  width: 100% !important;
  cursor: pointer !important;
}

.mobile-filters-btn.selected {
  background-color: #E7131A !important;
  color: white !important;
}

.dropdownMenuItem {
  font-family: "Roboto" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: #000 !important;
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.dropdownMenuItem.cancel {
  color: #E7131A !important;
}

.header-button {
  background-color: #bbb !important;
  color: #333 !important;
  padding: 8px 12px !important;
  height: 36px !important;
  min-width: 48px !important;
  font-family: "Roboto" !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 16px !important;
  font-style: normal !important;
  border-radius: 4px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  gap: 8px !important;
}

.header-button-mobile {
  background-color: #E7131A !important;
  color: #FFFFFF !important;
  height: 25px !important;
  min-width: 25px !important;
  font-family: "Roboto" !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 13px !important;
  font-style: normal !important;
  border-radius: 4px !important;
  font-weight: 700 !important;
  text-transform: none !important;
}

.underline {
  background-color: #FFF !important;
  color: #E7131A !important;
  border: 1.5px solid #E7131A !important;
}

.header-button.red {
  background-color: #E7131A !important;
  color: #FFFFFF !important;
}

.options-button {
  background-color: #BBB !important;
  color: #333 !important;
  padding: 2px !important;
  height: 36px !important;
  min-width: 40px !important;
  font-size: 20px !important;
  border-radius: 8px !important;
}

.full-button {
  background: #E7131A !important;
  color: #FFF !important;
  padding: 8px 12px !important;
  height: 48px !important;
  width: 100% !important;
  font-family: "Roboto" !important;
  font-size: 16px !important;
  font-style: normal !important;
  border-radius: 4px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  gap: 8px !important;
}

.disabledIcon {
  color: rgba(0, 0, 0, 0.26);
}

.justifyBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.productHeader {
  background: #DDD;
  border-radius: 8px;
  padding: 8px 8px 8px 16px;
  margin: 0px;
}

.mobile-disabled {
  background: rgba(0, 0, 0, 0.26) !important;
  color: white !important;
}

.disabled {
  background-color: #EEE !important;
  color: #888 !important;
  border: 0px !important;
}

.disabledIcon {
  color: rgba(0, 0, 0, 0.26);
}

.red-outline-btn-Scanner {
  background-color: #fff !important;
  color: #e7131a !important;
  padding: 8px 12px !important;
  min-height: 24px;
  max-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-family: "Roboto" !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  text-transform: none !important;
  border: 1.5px solid #e7131a !important;
  gap: 8px !important;
  border-radius: 4px;
  text-wrap: nowrap;
}

.mobile-list-btn {
  background: #e7131a !important;
  text-transform: none !important;
  color: white !important;
  height: 25px !important;
  padding: 0px !important;
  width: 100% !important;
}

.mobile-list-btn.marked-batch {
  background: green !important;
}

.mobile-list-btn.on-hold {
  background: #FF8042 !important;
}