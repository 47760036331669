

.column-label {
    font-weight: bold;
    margin-right: 5px;
}

.header-drawer{
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
    font-style: normal !important;
    color: #676767 !important;
    letter-spacing: 0.06px !important;
}

.value-drawer {
    font-size: 13px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    font-style: normal !important;
    color: black !important;
}

