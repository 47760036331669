.inputField{
  text-align: left !important;
   p{
     text-align: left !important;
     margin-bottom: 3px !important;
     margin-top: 3px !important;
   }
   .MuiSelect-select{
     text-align: left !important;
   }
   .MuiFormControl-root{
     min-width: 100% !important;
     margin-top: 3px !important;
     margin-bottom: 3px !important;
   }
   .MuiFormControlLabel-root{
     margin-left: 0px !important;
   }
 }
 .MuiDataGrid-row.Mui-selected{
   background-color: rgb(241, 181, 177) !important
 }
 .Mui-checked{
   color: #D93A2F !important
 }
 .Mui-checked.Mui-disabled{
  color: rgba(0, 0, 0, 0.26) !important;
 }
 .MuiDataGrid-cell:focus {
   outline: rgb(241, 181, 177) !important
}
.inputFieldQuantity{
  text-align: left !important;
   p{
     text-align: left !important;
     margin-bottom: 3px !important;
     margin-top: 3px !important;
   }
   .MuiSelect-select{
     text-align: left !important;
   }
   .MuiFormControl-root{
     max-width: 98% !important;
     margin-top: 3px !important;
     margin-bottom: 3px !important;
   }
   .MuiFormControlLabel-root{
     margin-left: 0px !important;
   }
 }