.topHeading {
    width: 100%;
}

.heading>p {
    color: '#333333';
    font-size: 20px !important;
    font-weight: 700;
}

.heading {
    margin: 10px 2px 16px 2px;
}

.gridContainer {
    display: flex;
    flex-direction: column !important;
}