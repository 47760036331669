@media screen and (max-width: 1000px) and (orientation: landscape) {
  .landscape {
    position: absolute;
    top: 0;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  //   .scandit-container {
  //       height: 200vh !important;
  //       display: block;
  //       transform-origin: top left;
  //       transform: rotate(-90deg) translate(-100%);
  //       margin-top: 0%;
  //       margin-left: 30px;
  //       width: 75vh !important;
  //  }
  .MuiBottomNavigation-root {
    display: none !important;
  }

  .mobileNabvarContainer {
    display: none !important;
  }

  //   .hideWhileRotate {
  //       display: none !important;
  //  }
  .containerTypeBarCodeMbl {
    display: none !important;
  }
}

.pick-slip-button {
  background-color: #eeeeee !important;
  color: #333 !important;
  padding: 8px 12px !important;
  height: 36px !important;
  min-width: 48px !important;
  font-family: "Roboto" !important;
  font-size: 16px !important;
  font-style: normal !important;
  border-radius: 4px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  gap: 8px !important;
}

.justifyBetween {
  display: flex;
  justify-content: space-between;
}

.productHeader {
  background: #DDD;
  border-radius: 8px;
  padding: 8px 8px 8px 16px;
  margin: 0px;
}

.scan-button {
  background: #E7131A !important;
  color: #FFF !important;
  padding: 8px 12px !important;
  height: 48px !important;
  width: 100% !important;
  font-family: "Roboto" !important;
  font-size: 16px !important;
  font-style: normal !important;
  border-radius: 4px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  gap: 8px !important;
}

.scan-button.disabled {
  background: #EEE !important;
  color:#888 !important;
}

.mobile-disabled {
  background: rgba(0, 0, 0, 0.26) !important;
  color: white !important;
}

.scan-desc{
  color: #333 !important;
  font-family: "Roboto" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-style: normal !important;
}

.scan-heading{
  color: #676767 !important;
  font-family: "Roboto" !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

.scan-value{
  color: #333333 !important;
  font-family: "Roboto" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-style: normal !important;
}