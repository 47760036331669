.dialogButton {
    font-size: 12px !important;
}

.dialogMinHeight{
    min-height: 70px;
}
.itemDialogBorder {
    border-bottom: 1px solid #dedada;    
}
.dialogWidth{
    width:  400px;
    @media only screen and (max-width: 640px) {
        width: 300px;
    }
}
// .dialogHeader {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
// }


.dialogWidth {
    width: 450px;
    @media only screen and (max-width: 640px) {
        width: 300px;
    }
}

.labelDiv {
    display: flex;
    // width: 500px;
    justify-content: space-between;
}

.labelColumn {
    display: flex;
    font-size: 12px;
    flex-direction: column;

    .labelRow {
        width: 400px;
        margin: 5px 0;
        display: flex;
        word-break: break-word;
        justify-content: space-between;

        
        @media only screen and (max-width: 640px) {
            width: auto;
        }

        div {
            width: 48%;
        }

        div:nth-child(1) {
            font-weight: bold;
        }

        div:nth-child(2) {
            text-align: right;
        }
    }
}

.voidDiv {
    width: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media only screen and (max-width: 640px) {
        width: 300px;
    }

    .voidActions {
        margin: 5px 0;
        display: flex;
        justify-content: center;
    }

    .voidLoader {
        margin: 15px 0;
    }
}

.voidLabel {
    font-size: 18px;
    font-weight: 500;
    word-break: break-word;
}