.custom-styles {
    --ReactInputVerificationCode-itemWidth: 2.5rem;
    --ReactInputVerificationCode-itemHeight: 3.5rem;
    display: flex;
    justify-content: center
  }
  
  .custom-styles .ReactInputVerificationCode__item {
    position: relative;
    color: #262626;
    font-weight: 500;
  }
  
  .custom-styles .ReactInputVerificationCode__item,
  .custom-styles .ReactInputVerificationCode__item.is-active {
    box-shadow: none;
  }
  
  .custom-styles .ReactInputVerificationCode__item:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 2px;
    background-color: #b3acac;
    transition: background-color 0.2s ease-out;
  }
  
  .custom-styles .ReactInputVerificationCode__item.is-active:after {
    background-color: #046cde;
  }

  .externalView{
    background: #eee;
    background: url("../../Assets/3442872.jpg");
    width: 100%;
    min-height: 100%;
    height: fit-content;
    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
  }
  .custom-heading {
    font-family: "Segoe UI","Segoe UI Web (West European)",-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 39px;
    font-weight: 500;
    color: #ffffff;
    margin-top: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    letter-spacing: .06em;
  }
  .innerCodeDiv{
    justify-content: center;
    background: white;
    
  }

  .submitBtn{
    background: #155454 !important;
  }
  .submitBtnDisabled{
    background-color: rgba(0, 0, 0, 0.26) !important;
  }
  .tealBackground{
    background: #227575 !important;
  }
  .tealColor{
    color: #227575 !important;;
  }
  .customerDetailsBoxExternal{
    position: fixed;
    width: 100%;
    bottom: 0;
  }