.filterDrawer {
    width: 420px;
    padding: 24px;
    background: #f9f9f9;
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 100%;
    padding-bottom: 24px;
    overflow-y: scroll;

    @media (max-width: 768px) {
        width: 100vw;
    }

    .heading {
        font-size: 26px;
        font-weight: 700;
        font-family: "Roboto", sans-serif;
    }

    .filterSection {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .filterSectionTitle {
            font-size: 13px;
            font-weight: 500;
            color: #000;
        }

        .filters {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }

        .item {
            padding: 2px;
            padding-right: 10px;
            width: 100%;
            height: 48px;
            border-radius: 8px;
            background-color: #eeeeee;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .filterName {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
            width: min-content;
            text-wrap: nowrap;
        }

        .filterOutline {
            border: 1px solid red;
        }
    }
}

.report-card-heading {
    font-family: "Roboto" !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 26px !important;
    margin-bottom: 10px !important;
    gap: 16px !important;
}