.textFieldError{
  color: #d32f2f;
  font-weight: 400 !important; 
  font-size:0.75rem !important; 
  margin-left: 5px !important;
}

.listItem{
  margin-left: 2px !important;
  padding: 0px !important;
}