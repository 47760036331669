.MuiBadge-badge.MuiBadge-anchorOriginTopRightRectangle {
    font-size: 0.95rem;
}

.GrandTotalContainerHeading {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 3.2px;
    color: #505050;
    font-size: 1.1rem;
}

.verticalCenterAlign {
    display: flex;
    align-items: center;
}

.GrandTotalContainerIcon {
    color: white;
    margin-right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: #fa847c;
}

.GrandTotalContainerValue {
    font-size: 1.5rem;
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    line-height: 1.235;
    letter-spacing: 0.00735em;
}

.cardsHeader {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin: 32px 0px 32px 0px;
}

.pick-border{
    height: 159px !important;
    border-top: 8px solid #1491F6 !important;
}

.pack-border {
    height: 159px !important;
    border-top: 8px solid #FFA514 !important;
}

.receive-border {
    height: 159px !important;
    border-top: 8px solid #338A5D !important;
}

.pickup-border {
    height: 159px !important;
    border-top: 8px solid #3A3C59 !important;
}