.DrawerRow {
  width: 98%;
  margin: 2px auto;

  .DrawerRowHeader {
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    text-align: left;
  }

  .DrawerRowContent {
    width: 80%;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: flex-start;

    span {
      cursor: pointer;
    }
  }
}

.DrawerLine {
  width: calc(100% - 20px);
  // height: 30px;
  // color: #FFFFFF;
  color: white;
  padding: 10px 10px;
  font-family: "Roboto";
  font-style: normal;
  font-size: 14px;
  cursor: pointer;
  // margin: 2px auto;
  font-weight: 700;
  text-align: left;
  background: inherit;
}

.SelectedDrawerLine {
  // color: #D93A2F;
  color: black;
  font-weight: 600;
  // background: #FFFFFF;
  width: calc(100% - 20px);
  // border-right: 1px solid #D93A2F;
}

.hvr-bounce-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.hvr-bounce-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*background: #E16159;*/
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-bounce-to-right:hover,
.hvr-bounce-to-right:focus,
.hvr-bounce-to-right:active {
  // color: #D93A2F;
  color: #ffffff;
  font-weight: 600;
  // width: calc(100% - 20px);
  // margin-left: -3px;
}

.hvr-bounce-to-right:hover:before,
.hvr-bounce-to-right:focus:before,
.hvr-bounce-to-right:active:before {
  // background: #FFFFFF;
  background: #d93a2f;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}


.close-icon {
  cursor: pointer;
  border-radius: 36px;
  height: 26px;
  width: 26px;
  background: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: 8px;
  margin-top: 8px;
  padding: 4px;
}