.inputField{
  text-align: left !important;
   p{
     text-align: left !important;
     margin-bottom: 3px !important;
     margin-top: 3px !important;
   }
   .MuiSelect-select{
     text-align: left !important;
   }
   .MuiFormControl-root{
     min-width: 100% !important;
     margin-top: 3px !important;
     margin-bottom: 3px !important;
   }
   .MuiFormControlLabel-root{
     margin-left: 0px !important;
   }
 }
 .MuiDataGrid-row.Mui-selected{
   background-color: rgb(241, 181, 177) !important
 }
 .Mui-checked{
   color: #D93A2F !important
 }
 .Mui-checked.Mui-disabled{
  color: rgba(0, 0, 0, 0.26) !important;
 }
 .MuiDataGrid-cell:focus {
   outline: rgb(241, 181, 177) !important
}

.storesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;

}

.storeDataTable {
  border-right: 2px solid #dddddd;
  border-left: 2px solid #dddddd;
  border-bottom: 2px solid #dddddd;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 0px 0px 5px 0px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .storeRow {
    padding-left: 8px;
    padding-right: 8px;
    color: black;
    width: 100%;
    border-bottom: 1px solid #DDDDDD;
    display: flex;
    flex-direction: row;

    .cellLeft {
      padding-top: 11px;
      min-width: 138px;
      height: 100%;
      display: flex;
      flex-direction: column;
      color: #676767;
      font-size: 12px;
      gap: 8px;
      align-items: start;
      text-wrap: nowrap;
      .cellRadio{
        margin-left: -10px;
      }
      .cellHeader {
        text-align: start;
        height: 32px;
      }
    }

    .cellRight {
      margin-top: 5px;
      padding-top: 11px;
      padding-left: 8px;
      width: 95%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: black;
      font-size: 12px;
      align-items: start;
      .cellData {
        width: 100%;
        text-align: start;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 2px;
        height: 32px;
      }
      .cellCheckBox{
        height:30px;
        margin-left: -9px;
        margin-top: -9px;
      }
    }
  }
}

.resource-heading {
  font-family: "Roboto" !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  text-align: left !important;
}

.resource-value {
  color: #676767 !important;
  font-family: "Roboto" !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: 0.065px !important;
  text-align: left !important;
}

.resource-heading.mobile{
  font-size: 17px !important;
}

.resourcePaginationMobile{
  display: flex;
  justify-content: center;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border: 2px solid #dddddd;
  box-shadow: 0px 0px 8px 0px #0000000f;
  background: white !important;
  color: black !important;

  .MuiPaginationItem-page.Mui-selected {
    background: white;
    color: black !important;
  }

  .MuiPaginationItem-page.Mui-selected:hover {
    background: white !important;
    color: #d93a2f !important;
  }

  .MuiTablePagination-root,
  .MuiSvgIcon-root {
    color: black !important;
  }
  .MuiToolbar-root{
    justify-content: center;
    padding: 0px;
  }
}