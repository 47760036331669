.address-heading {
    font-family: "Roboto" !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 24px !important;
}

.address-value {
    color: #676767 !important;
    font-family: "Roboto" !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    letter-spacing: 0.065px !important;
}