.history-heading {
    font-family: "Roboto" !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 26px !important;
    gap: 16px !important;
}

.history-card-Content {
    padding: 16px 20px 16px 20px;
}