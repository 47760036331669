.panel-container {
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        font-size: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;           
        padding: 30px;
        max-width: 350px;
      }
      
      .panel-container strong {
        line-height: 20px;
      }
      
      .ratings-container {
        display: flex;
        margin: 5px 0;
        text-align: center;
      }
      
      .rating {
        flex: 1;
        cursor: pointer;
        padding: 15px;
        margin: 10px 5px;
      }
      
      .rating:hover,
      .rating.active {
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.315);
      }
      
      .rating img {
        width: 40px;
      }
      
      .rating small {
        color: #555;
        display: inline-block;
        margin: 10px 0 0;
      }
      
      .rating:hover small,
      .rating.active small {
        color: #111;
      }
      
      .btn {
        background-color: #302d2b;
        color: #fff;
        border: 0;
        border-radius: 4px;
        padding: 12px 30px;
        font-family: inherit;
        cursor: pointer;
      }
      
      .btn:focus {
        outline: none;
      }
      
      .btn:active {
        transform: scale(0.98);
      }
      
      .fa-heart {
        color: red;
        font-size: 30px;
        margin-bottom: 10px;
      }