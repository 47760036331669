.pendingReceiptsDataTable {
    border: 2px solid #dddddd;
    box-shadow: 0px 0px 8px 0px #0000000f;

    .rdt_TableHeadRow {
        background: white;
        color: black;
        font-size: 14px !important;
        text-align: start;

        .rdt_TableCol {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }

    .rdt_TableRow {
        border-top: 1px solid #DDDDDD !important;
        border-bottom: 0px !important;
        font-family: "Roboto Mono";
    }

    .rdt_TableRow:nth-child(odd) {
        background-color: #f9f9f9;
        color: black;
    }

    .rdt_TableCell {
        font-size: 13px !important;
        text-align: start;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

.pendingReceiptsPaginationWeb {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border: 2px solid #dddddd;
    box-shadow: 0px 0px 8px 0px #0000000f;
    background: white !important;
    color: black !important;

    .MuiPaginationItem-page.Mui-selected {
        background: white;
        color: black !important;
    }

    .MuiPaginationItem-page.Mui-selected:hover {
        background: white !important;
        color: #d93a2f !important;
    }

    .MuiTablePagination-root,
    .MuiSvgIcon-root {
        color: black !important;
    }
}


.red-outline-btn {
    background-color: #fff !important;
    color: #e7131a !important;
    padding: 8px 12px !important;
    min-height: 24px;
    max-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-family: "Roboto" !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    text-transform: none !important;
    border: 1.5px solid #e7131a !important;
    gap: 8px !important;
    border-radius: 4px;
    text-wrap: nowrap;
}

.mobileFulfilmentDataTable {
    border-radius: 16px;
    border: 2px solid #dddddd;
    box-shadow: 0px 0px 8px 0px #0000000f;
    width: 95%;
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    margin-top: 10px;

    .mobileRow {
        padding-left: 8px;
        padding-right: 8px;
        color: black;
        width: 100%;
        border-bottom: 1px solid #DDDDDD;
        display: flex;
        flex-direction: row;

        .mobileCellLeft {
            padding-top: 11px;
            border-right: 1px solid #DDDDDD;
            min-width: 90px;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
            color: #676767;
            font-size: 12px;
            align-items: start;
            text-wrap: nowrap;

            .cellMeta {
                text-align: start;
                height: 32px;
            }
        }

        .mobileCellRight {
            padding-top: 11px;
            padding-left: 8px;
            width: 95%;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
            color: black;
            font-size: 12px;
            align-items: start;
            overflow: hidden;

            .orderIdLabel {
                display: flex;
                flex-direction: row;
                gap: 8px;
                flex-wrap: nowrap;
                overflow-x: scroll;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 32px;
            }

            .cellInfoMain {
                display: flex;
                flex-direction: row;
                gap: 8px;
                flex-wrap: nowrap;
                overflow-x: scroll;
                height: 100%;
                align-items: center;
            }

            .cellInfo {
                width: 100%;
                text-align: start;
                text-wrap: nowrap;
                overflow-x: hidden;
                text-overflow: ellipsis;
                margin-bottom: 2px;
                height: 32px;
            }
        }
    }
}

.receiveMobileLabel {
    color: rgba(0, 0, 0, 0.6);
}

.clickable-copyable-link {
    color: rgb(25, 118, 210);
    text-decoration: underline rgba(25, 118, 210, 0.4);
    cursor: pointer;
    user-select: text;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.875rem; 
    @media (max-width: 600px) {
        font-size: 0.75rem;
    }
  }
  